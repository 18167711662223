import React, { useState } from "react";
import { useEffect } from "react";
import { axiosInstance } from "../API/authapi.tsx";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Lossgraph from "../Home/Lossgraph.jsx";
import Pie from "./Pie.jsx";

function Work() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [inProgress, setInProgress] = useState(0);
  const [onHold, setOnHold] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [totalWork, setTotalWork] = useState(0);
  const [rejected, setRejected] = useState(0);
  const [toFollow, setFollow] = useState(0);
  const [todayWork, setTodayWork] = useState(0);
  const [todaySubmit, setTodaySubmit] = useState(0);


  const toggleTheme = () => {
    // Toggle between dark and light themes
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);

    // Save theme preference to localStorage
    localStorage.setItem("theme", newTheme);
  };

  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme : "dark"; // Default to dark theme if no preference is saved
  };

  const [theme, setTheme] = useState(getInitialTheme());
  const currentDate = new Date();

  // Calculate the first day of the current month
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Format the date as "YYYY-MM-DD" for the input value
  const initialStartDate = formatDate(firstDayOfMonth);

  // Function to format the date as "YYYY-MM-DD"
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based index
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [startDate, setStartDate] = useState(initialStartDate);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const today = new Date();

        const response = await axiosInstance.post('/work/dashboard', {
          startDate: startDate,
          endDate: today.toISOString(),
        });

        const { totalCustomers, totalCustomersCreatedToday, totalOnHold, totalInProgress, totalCancelled, totalCompleted } = response.data;

        if (totalCustomers !== null) {
          setTotalWork(totalCustomers);
        }
        if (totalCustomersCreatedToday !== null) {
          setTodayWork(totalCustomersCreatedToday);
        }
        if (totalOnHold !== null) {
          setOnHold(totalOnHold);
        }
        if (totalInProgress !== null) {
          setInProgress(totalInProgress);
        }
        if (totalCancelled !== null) {
          setRejected(totalCancelled);
        }
        if (totalCompleted !== null) {
          setCompleted(totalCompleted);
        }

        // ... update other state variables as needed




        setIsLoading(false);
      } catch (error) {
        // If the request results in a 401 Unauthorized status, redirect to /#/accounts
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          // Handle other errors if needed
          console.error('Error fetching financial overview:', error);
        }
      }
    };

    fetchData();
  }, [startDate]);
  const getEllipseSvg = () => {
    const darkTheme = (
      <svg width="253" height="494" viewBox="0 0 253 494" fill="none" xmlns="http://www.w3.org/2000/svg" className=" fixed left-0 bottom-5 -z-5">
        <g filter="url(#filter0_f_45_348)">
          <ellipse cx="-9.5" cy="262" rx="162.5" ry="162" fill="url(#paint0_linear_45_348)" />
        </g>
        <defs>
          <filter id="filter0_f_45_348" x="-272" y="0" width="525" height="524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_45_348" />
          </filter>
          <linearGradient id="paint0_linear_45_348" x1="-9.5" y1="100" x2="-9.5" y2="424" gradientUnits="userSpaceOnUse">
            <stop stop-color="#070E23" />
            <stop offset="1" stop-color="#2D303E" />
          </linearGradient>
        </defs>
      </svg>
    );
    const lightTheme = (
      <svg width="253" height="494" viewBox="0 0 253 494" fill="none" xmlns="http://www.w3.org/2000/svg" className=" fixed left-0 bottom-5 -z-5">
        <g filter="url(#filter0_f_3_4)">
          <ellipse cx="-9.5" cy="262" rx="162.5" ry="162" fill="url(#paint0_linear_3_4)" />
        </g>
        <defs>
          <filter id="filter0_f_3_4" x="-272" y="0" width="525" height="524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_3_4" />
          </filter>
          <linearGradient id="paint0_linear_3_4" x1="-9.5" y1="100" x2="-9.5" y2="424" gradientUnits="userSpaceOnUse">
            <stop stop-color="#A9BCFF" />
            <stop offset="1" stop-color="#CECDFC" />
          </linearGradient>
        </defs>
      </svg>
    );
    return theme === 'dark' ? darkTheme : lightTheme;
  }

  const getCircleSvg = () => {
    const darkTheme = (
      <svg xmlns="http://www.w3.org/2000/svg" width="159" height="358" viewBox="0 0 159 358" fill="none" className=" fixed right-0 top-2 -z-5">
        <g filter="url(#filter0_f_45_347)">
          <circle cx="179" cy="179" r="79" fill="url(#paint0_linear_45_347)" />
        </g>
        <defs>
          <filter id="filter0_f_45_347" x="0" y="0" width="358" height="358" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_45_347" />
          </filter>
          <linearGradient id="paint0_linear_45_347" x1="179" y1="100" x2="179" y2="258" gradientUnits="userSpaceOnUse">
            <stop stop-color="#392A40" />
            <stop offset="1" stop-color="#24236A" />
          </linearGradient>
        </defs>
      </svg>
    );

    const lightTheme = (
      <svg xmlns="http://www.w3.org/2000/svg" width="159" height="358" viewBox="0 0 159 358" fill="none" className=" fixed right-0 top-2 -z-5">
        <g filter="url(#filter0_f_3_3)">
          <circle cx="179" cy="179" r="79" fill="url(#paint0_linear_3_3)" />
        </g>
        <defs>
          <filter id="filter0_f_3_3" x="0" y="0" width="358" height="358" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_3_3" />
          </filter>
          <linearGradient id="paint0_linear_3_3" x1="179" y1="100" x2="179" y2="258" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E3A9FF" />
            <stop offset="1" stop-color="#ABA9FF" />
          </linearGradient>
        </defs>
      </svg>
    );

    return theme === 'dark' ? darkTheme : lightTheme;
  };





  if (isLoading) {
    console.log(isLoading);
    return (
      <div className={`${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'} flex justify-center items-center h-screen`}>
        <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-black'}`}>Loading...</p>
      </div>

    );
  }


    return (
      <div className={`relative w-full p-6 flex flex-col gap-4 ${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'}`}>
        {getCircleSvg()}
        {getEllipseSvg()}

        <div className="flex flex-row items-center gap-4 justify-between">
          <h1 className={`w-2/5 text-2xl md:text-3xl lg:text-4xl font-rubik mb-2 md:mb-7 font-bold ${theme === 'dark' ? 'text-gray-300' : 'text-black'}`}>
            Dashboard
          </h1>
          <input
            type="date"
            className="w-2/5 h-6 bg-transparent text-slate-400 border border-slate-700 border-solid p-3 font-rubik rounded-md pt-5 pb-5 z-50"
            value={startDate} // Set the value of the input to startDate
            onChange={(e) => setStartDate(e.target.value)} // Update startDate when input value changes
          />
          <label className="theme-switch">
            <input type="checkbox" className="theme-switch__checkbox" checked={theme === 'dark'} onChange={toggleTheme} />
            <div className="theme-switch__container">
              <div className="theme-switch__clouds"></div>
              <div className="theme-switch__stars-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 55" fill="none">
                  {/* ... (stars SVG path) */}
                </svg>
              </div>
              <div className="theme-switch__circle-container">
                <div className="theme-switch__sun-moon-container">
                  <div className="theme-switch__moon">
                    <div className="theme-switch__spot"></div>
                    <div className="theme-switch__spot"></div>
                    <div className="theme-switch__spot"></div>
                  </div>
                </div>
              </div>
            </div>
          </label>
        </div>

        <div className="w-full ">
          <div className="BalanceBlue text-white rounded-3xl p-6 md:p-10 flex flex-col justify-center items-start md:h-36 font-rubik h-44 relative overflow-hidden ">
            <div className=" bg-slate-50 bg-opacity-10 w-28 h-28 rounded-full absolute  -top-4 right-5 -z-0 "></div>
            <div className=" bg-slate-50 bg-opacity-10 w-40 h-40 rounded-full absolute  -top-1 -right-10"></div>
            <p className="font-light mb-4 text-slate-300">Total Work</p>
            <h3 className="font-bold text-5xl md:text-4xl lg:text-6xl">
              {totalWork}
            </h3>
          </div>
        </div>
        <div className="flex gap-4 font-rubik relative z-5">
          <a
            href="/#/profit"
            className="ProfitGrenn text-white w-full md:w-1/2 rounded-3xl p-4 pl-7 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36 h-32 relative overflow-hidden"
          >
            <div className="bg-slate-50 bg-opacity-10 w-16 h-16 rounded-full absolute -top-5 right-0 -z-0"></div>
            <div className="bg-slate-50 bg-opacity-10 w-24 h-24 rounded-full absolute -top-1 -right-10"></div>
            <p className="font-extralight mb-1 text-gray-200">On Progress</p>
            <h3 className="font-bold text-5xl md:text-4xl lg:text-6xl flex items-center">
              <p className="ml-1.5 text-3xl md:text-xl">{inProgress}</p>
            </h3>
          </a>
          <a
            href="/#/loss"
            className="bg-gray-900 text-white w-full md:w-1/2 rounded-3xl p-4 pl-7 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36 h-32 relative overflow-hidden"
          >
            <div className="bg-slate-50 bg-opacity-10 w-16 h-16 rounded-full absolute -top-5 right-0 -z-0"></div>
            <div className="bg-slate-50 bg-opacity-10 w-24 h-24 rounded-full absolute -top-1 -right-10"></div>
            <p className="font-extralight mb-1 text-gray-200">On Hold</p>
            <h3 className="font-bold text-5xl md:text-4xl lg:text-6xl flex items-center">
              <p className="ml-1.5 text-3xl md:text-xl">{onHold}</p>
            </h3>
          </a>
        </div>


        <div className="flex gap-4 font-rubik">
          <a
            href="/#/invest"
            className="bg-amber-400 text-white w-full md:w-1/2 rounded-3xl p-4 pl-7 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36 h-32 relative overflow-hidden"
          >
            <div className="bg-gray-400 bg-opacity-5 w-16 h-16 rounded-full absolute -top-5 right-0 -z-0"></div>
            <div className="bg-gray-400 bg-opacity-5 w-24 h-24 rounded-full absolute -top-1 -right-10"></div>
            <p className="font-light mb-1 text-gray-200 text-sm md:text-base">completed</p>
            <h3 className="font-bold text-4xl md:text-3xl lg:text-4xl flex items-center">
              <p className="ml-1.5 text-3xl md:text-xl">{completed}</p>
            </h3>
          </a>
          <a
            href="/#/saving"
            className="LoosRed text-white w-full md:w-1/2 rounded-3xl p-4 pl-7 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36 h-32 relative overflow-hidden"
          >
            <div className="bg-gray-400 bg-opacity-5 w-16 h-16 rounded-full absolute -top-5 right-0 -z-0"></div>
            <div className="bg-gray-400 bg-opacity-5 w-24 h-24 rounded-full absolute -top-1 -right-10"></div>
            <p className="font-light mb-1 text-gray-200 text-sm md:text-base">Rejected</p>
            <h3 className="font-bold text-4xl md:text-3xl lg:text-4xl flex items-center">
              <p className="ml-1.5 text-3xl md:text-xl">{rejected}</p>
            </h3>
          </a>
        </div>

        <div className="border z-40 border-blue-600 border-solid text-blue-200 w-full rounded-2xl p-4 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36">
          <p className="font-extralight mb-1 text-blue-600 ">
          <Pie
        onHold={parseInt(onHold, 10)}
        inProgress={parseInt(inProgress, 10)}
        rejected={parseInt(rejected, 10)}
        completed={parseInt(completed, 10)}
      />
           </p>

        </div>
        <div className=" flex flex-col gap-4 relative z-10">
          <div className="goodbox border border-blue-600 flex justify-center items-center p-3 gap-2 rounded-full">
            <div className=" w-7 h-7 bg-green-300 rounded-full flex justify-center items-center p-2">
              <svg
                width="64"
                height="64"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8"
              >
                <g
                  fill="none"
                  stroke="#ffffff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M9.5 3.5h4v4" />
                  <path d="M13.5 3.5L7.85 9.15a.5.5 0 0 1-.7 0l-2.3-2.3a.5.5 0 0 0-.7 0L.5 10.5" />
                </g>
              </svg>
            </div>
            <p className={`goodnews font-rubik ${theme === 'dark' ? 'text-slate-50' : 'text-black'}`}>
              You have more Leads compare to last month{" "}
            </p>
          </div>
          <div className="badbox border border-blue-600 flex justify-center items-center p-3 rounded-full gap-2">
            <div className=" w-7 h-7 bg-red-400 rounded-full flex justify-center items-center p-2">
              <svg className="w-8 h-8"
                width="64" height="64" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M9.5 10.5h4v-4" />
                  <path d="M13.5 10.5L7.85 4.85a.5.5 0 0 0-.7 0l-2.3 2.3a.5.5 0 0 1-.7 0L.5 3.5" />
                </g>
              </svg>
            </div>
            <p className={`goodnews font-rubik ${theme === 'dark' ? 'text-slate-50' : 'text-black'}`}>
              Convertion is lesser compare to last month{" "}
            </p>
          </div>
        </div>


        {/* <div className="flex gap-4 font-rubik">
        <a
          href="/#/invest"
          className="border border-blue-600 border-solid text-blue-200 w-full md:w-1/2 rounded-2xl p-4 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36"
        >
          <p className="font-extralight mb-1 text-blue-600">Investments</p>
          <h3 className="font-bold text-xl md:text-4xl lg:text-6xl">
            <span className="font-light text-sm md:text-2xl mr-1">₹</span>
            {investmentAmount}
          </h3>
        </a>
        <a
          href="/#/saving"
          className="border border-blue-600 border-solid text-blue-200 w-full md:w-1/2 rounded-2xl p-4 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36"
        >
          <p className="font-extralight mb-1 text-blue-600">Savings</p>
          <h3 className="font-bold text-xl md:text-4xl lg:text-6xl">
            <span className="font-light text-sm md:text-2xl mr-1">₹</span>
            {savingsAmount}
          </h3>
        </a>
      </div> */}
        {/* <div className="flex gap-4 font-rubik">
        <div className="border border-blue-600 border-solid text-blue-200 w-full md:w-1/2 rounded-2xl p-4 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36">
          <p className="font-extralight mb-1 text-blue-600">Credits</p>
          <h3 className="font-bold text-xl md:text-4xl lg:text-6xl">
            <span className="font-light text-sm md:text-2xl mr-1">₹</span>
            {creditAmount}
          </h3>
        </div>
        <a
          href="/#/donation"
          className="border border-blue-600 border-solid text-blue-200 w-full md:w-1/2 rounded-2xl p-4 md:p-6 lg:p-10 flex flex-col justify-center items-start md:h-36"
        >
          <p className="font-extralight mb-1 text-blue-600">Donations</p>
          <h3 className="font-bold text-xl md:text-4xl lg:text-6xl">
            <span className="font-light text-sm md:text-2xl mr-1">₹</span>
            {donationAmount}
          </h3>
        </a>
      </div> */}




        <div className="w-full ">
          <div className="bg-gray text-white rounded-3xl p-6 md:p-10 flex flex-col justify-center items-start md:h-36 font-rubik h-44 relative overflow-hidden ">
            <div className=" bg-gray-600 bg-opacity-5 w-28 h-28 rounded-full absolute  -top-4 right-5 -z-0 "></div>
            <div className=" bg-gray-600 bg-opacity-5 w-40 h-40 rounded-full absolute  -top-1 -right-10"></div>
            <p className="font-light mb-4 text-gray-300">Today Total Work</p>
            <h3 className="font-bold text-4xl md:text-4xl lg:text-6xl">
              {todayWork}
            </h3>
          </div> 
        </div>
        <div className="w-full ">
          <div className="bg-gray text-white rounded-3xl p-6 md:p-10 flex flex-col justify-center items-start md:h-36 font-rubik h-44 relative overflow-hidden ">
            <div className=" bg-gray-600 bg-opacity-5 w-28 h-28 rounded-full absolute  -top-4 right-5 -z-0 "></div>
            <div className=" bg-gray-600 bg-opacity-5 w-40 h-40 rounded-full absolute  -top-1 -right-10"></div>
            <p className="font-light mb-4 text-gray-300">Total Works To Be Submitted Today</p>
            <h3 className="font-bold text-4xl md:text-4xl lg:text-6xl">
              {todayWork}
            </h3>
          </div> 
        </div>
      </div>
    );
  }



export default Work;
