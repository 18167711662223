import React from "react";
import ReactApexChart from "react-apexcharts";

class Pie extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      series: [props.onHold, props.inProgress, props.completed, props.rejected],
      options: {
        chart: {
          width: 30,
          type: 'pie',
        },
        labels: ['On Hold', 'In Progress', 'Completed', 'Rejected'],
        responsive: [{
          breakpoint: 700,
          options: {
            chart: {
              width: 380
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart" style={{marginLeft:'-2rem'}}>
          <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width={380} />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default Pie;
