import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { axiosInstance } from "../../API/authapi.tsx";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";


function AddCredit() {
  const navigate = useNavigate();
  const [credit, setCredit] = useState("");
  const [amount, setAmount] = useState("");
  const [dueDate, setDueDate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [type, setType] = useState("");
  const [payment, setPayment] = useState("");
  const [loading, setLoading] = useState(false); // New state for loading


  useEffect(() => {

    const fetchData = async () => {
      try {

        const response = await axiosInstance.post('/credit/totalcreditsdata');




      } catch (error) {
        // If the request results in a 401 Unauthorized status, redirect to /#/accounts
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          // Handle other errors if needed
          console.error('Error fetching financial overview:', error);
        }
      }
    };

    fetchData();
  }, []);








  const handleAddCredit = () => {
    // Add validation logic
    if (!credit || !amount || !payment || !type) {
      toast.error("Please fill out all fields and select valid options");
      return;
    }

    // Disable the button during the request
    setLoading(true);

    // Create an object with the data to send in the request
    const creditData = {
      type: credit,
      amount: parseFloat(amount),
      category: type,
      ...(phoneNumber !== "" && { to: phoneNumber }),
      duedate: dueDate ? moment(dueDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null, // Format the date if it's not null
      payment,
    };

    // Make an Axios request
    axiosInstance
      .post("/credit/addcredits", creditData)
      .then((response) => {
        // Handle success
        toast.success("Credit added successfully");
        // You can also reset the form or navigate to another page upon success
      })
      .catch((error) => {
        // Handle error
        toast.error("Error adding Credit");
      })
      .finally(() => {
        // Enable the button after the request is completed
        setLoading(false);
      });
  };

  return (
    <div className="relative w-full p-10 flex flex-col gap-4 bg-slate-900 pb-20 h-full font-rubik min-h-screen z-10">
      <div
        aria-hidden="true"
        className="fixed  w-16 h-16 rounded-full bg-gradient-to-b from-blue-200 to-purple-200 blur-3xl mx-auto scale-y-150  top-60 right-0 -z-0"
      ></div>

      <div
        aria-hidden="true"
        className="fixed  w-24 h-16 rounded-full bg-gradient-to-b from-blue-500 to-purple-600 blur-3xl mx-auto scale-y-150  bottom-60 left-0 -z-0"
      ></div>
      <div>
        <h1 className="text-4xl lg:text-6xl font-rubik mb-7 font-bold text-gray-300 ">
          Add Credit
        </h1>
      </div>

      <div className="relative group rounded-lg w-full bg-opacity-20 flex flex-col gap-6">
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Credit Name</h2>
          <input
            name=""
            id=""
            value={credit}
            onChange={(e) => setCredit(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Enter Credit"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Amount</h2>
          <input
            name=""
            id=""
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Enter Amount"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Phone number</h2>
          <input
            name=""
            id=""
            type="number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Enter Phone number"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Due Date</h2>
          <DatePicker
            value={dueDate ? moment(dueDate, 'YYYY-MM-DD') : null}
            onChange={(date, dateString) => setDueDate(dateString)}
            className="my-custom-datepicker appearance-none hover:placeholder-shown:bg-red-800 relative text-red-100 bg-transparent ring-0 outline-none border border-neutral-500 text-red-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
          />
        </div>



        <div>
          {/* Allow users to input a custom category */}

        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Type of Credit</h2>
          <select
            name=""
            id=""
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Select Credit type"
          >
            <option className="bg-neutral-700 w-1/2 text-sm">Select Type</option>
            <option className="bg-neutral-700">tosent</option>
            <option className="bg-neutral-700">torecieve</option>
          </select>
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Payment Method</h2>
          <select
            name=""
            id=""
            value={payment}
            onChange={(e) => setPayment(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Select Payment Method"
          >
            <option className="bg-neutral-700 w-1/2 text-sm">Select Payment Method</option>
            <option className="bg-neutral-700 ">Cash</option>
            <option className="bg-neutral-700">Bank</option>
          </select>
        </div>
        <button
          onClick={handleAddCredit}
          disabled={loading}
          className={`flex gap-3 cursor-pointer text-white font-semibold bg-gradient-to-r from-purple-600 to-blue-900 px-7 py-3 rounded-lg border border-gray-600 hover:scale-105 duration-200 hover:text-gray-500 hover:border-gray-800 hover:from-slate-800 hover:to-gray-900 justify-center items-center bg-opacity-10 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {loading ? "Adding..." : "Add"}
        </button>
      </div>
    </div>
  );

}

export default AddCredit;
