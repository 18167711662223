import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { axiosInstance } from '../API/authapi.tsx';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSignup = async (e) => {
    e.preventDefault();

    // Prepare the data to be sent in the request
    const userData = {
      username: email,
      password: password,
    };

    try {
      // Make the Axios request to the server
      const response = await axiosInstance.post('/users/adduser', userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Assuming your API returns a success message
      toast.success('Registration successful!');
      console.log(JSON.stringify(response.data));
    } catch (error) {
      // Handle registration failure, show error message using toastr
      toast.error(error.response.data.error);
      console.log(error); 
    }
  };

  return (
    <div className='pt-3'>
      <h1 className="text-5xl lg:text-6xl font-rubik mb-7 font-bold text-gray-300 mt-5 ml-6">
        Accounts
      </h1>
      <div className="flex flex-col items-center mt-20 h-screen dark">
        <div className="w-11/12 max-w-md bg-gray-800 rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold text-gray-200 mb-4 font-rubik">Sign up</h2>
          <form className="flex flex-col" onSubmit={handleSignup}>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
              type="text"
            />
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder=" Enter Password"
              className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
              type="password"
            />
            <input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
              type="password"
            />
            <button
              className="bg-gradient-to-r from-indigo-500 to-blue-500 text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
              type="submit"
            >
              Sign Up 
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Signup;
