import axios from 'axios';

// Retrieve token from localStorage
const token =await localStorage.getItem('JWT_token');
console.log(token);

export const axiosInstance = axios.create({
  baseURL: 'https://jackcents.up.railway.app/',
  withCredentials: true,
  headers: {
    Authorization: `${token}` // Include the token in the Authorization header
  }
});
