import React, { useState, useEffect, useRef } from "react";
import { axiosInstance } from "../../API/authapi.tsx";
import Cookies from 'js-cookie';
import { useNavigate, BrowserRouter as Router, useLocation } from "react-router-dom";

function Addbutton() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };



  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsVisible(false); // Close the options menu when location changes
  }, [location]);

  const renderOptions = () => {
    // Check if URL contains '/management/'
    console.log(location);
    if (location.hash.includes('#/management')) {
      return (
        <>
          <a href="/#/management/addcustomer" className="">
            Customer
          </a>
          <a href="/#/management/addprofit" className="">
            Profit
          </a>
          <a href="/#/management/addexpense">Expense</a>
        </>
      );
    }
    else if(location.hash.includes('#/hotel')){
      return (
        <>
          <a href="/#/hotel/addbill" className="">
            Add Bill
          </a>
          <a href="/#/hotel/addproduct" className="">
            Add Product
          </a>
        </>
      );
    } 
    else {
      return (
        <>
          <a href="/#/addprofit" className="">
            Profit
          </a>
          <a href="/#/addexpense">Expense</a>
          <a href="/#/addinvestment" className="">
            Investment
          </a>
          <a href="/#/addsaving" className="">
            Savings
          </a>
          <a href="/#/addcredit">Credit</a>
          <a href="/#/adddonation">Donation</a>
        </>
      );
    }
  };

  return (
    <div className="">
      {isVisible && (
        <div
          ref={ref}
          className=" bg-slate-200 flex flex-col w-1/3 gap-3 justify-center items-center pt-3 pb-3 rounded-md bg-opacity-85 fixed bottom-24 right-16 z-50"
        >
          {renderOptions()}
          {/* <a href="/#/accounts" onClick={handleLogout}>
                        Logout
                    </a> */}
        </div>
      )}
      <button
        title="Add New"
        className="group cursor-pointer outline-none hover:rotate-90 duration-300 fixed bottom-20 right-5 z-50"
        onClick={toggleVisibility}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50px"
          height="50px"
          viewBox="0 0 24 24"
          className="stroke-blue-400 fill-none group-hover:fill-blue-800 group-active:stroke-blue-200 group-active:fill-blue-600 group-active:duration-0 duration-300"
        >
          <path
            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
            strokeWidth="1.5"
          ></path>
          <path d="M8 12H16" strokeWidth="1.5"></path>
          <path d="M12 16V8" strokeWidth="1.5"></path>
        </svg>
      </button>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <Addbutton />
    </Router>
  );
}
