import React, { useState, useEffect } from "react";
import Lossgraph from "../Lossgraph";
import ProgressBar from "@ramonak/react-progress-bar";
import { axiosInstance } from "../../API/authapi.tsx";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";



function Loss() {
  const navigate = useNavigate();
  const [lossAmount, setLossAmount] = useState(0);
  const [showGoodExpenses, setShowGoodExpenses] = useState(true);
  const [showBadExpenses, setShowBadExpenses] = useState(false);
  const [goodExpense, setGoodExpense] = useState([]);


  const [badExpense, setBadExpense] = useState([]);
  const [endDate, setEndDate] = useState(""); // Add state for end date

  const currentDate = new Date();

  // Calculate the first day of the current month
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Format the date as "YYYY-MM-DD" for the input value
  const initialStartDate = formatDate(firstDayOfMonth);

  // Function to format the date as "YYYY-MM-DD"
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based index
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [startDate, setStartDate] = useState(initialStartDate);
  const [isLoading, setIsLoading] = useState(true);

  const toggleExpenses = (type) => {
    setShowGoodExpenses(type === "good");
    setShowBadExpenses(type === "bad");
  };
  const getEllipseSvg = () => {
    const darkTheme = (
      <svg width="253" height="494" viewBox="0 0 253 494" fill="none" xmlns="http://www.w3.org/2000/svg" className=" fixed left-0 bottom-5 -z-5">
        <g filter="url(#filter0_f_45_348)">
          <ellipse cx="-9.5" cy="262" rx="162.5" ry="162" fill="url(#paint0_linear_45_348)" />
        </g>
        <defs>
          <filter id="filter0_f_45_348" x="-272" y="0" width="525" height="524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_45_348" />
          </filter>
          <linearGradient id="paint0_linear_45_348" x1="-9.5" y1="100" x2="-9.5" y2="424" gradientUnits="userSpaceOnUse">
            <stop stop-color="#070E23" />
            <stop offset="1" stop-color="#2D303E" />
          </linearGradient>
        </defs>
      </svg>
    );
    const lightTheme = (
      <svg width="253" height="494" viewBox="0 0 253 494" fill="none" xmlns="http://www.w3.org/2000/svg" className=" fixed left-0 bottom-5 -z-5">
        <g filter="url(#filter0_f_3_4)">
          <ellipse cx="-9.5" cy="262" rx="162.5" ry="162" fill="url(#paint0_linear_3_4)" />
        </g>
        <defs>
          <filter id="filter0_f_3_4" x="-272" y="0" width="525" height="524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_3_4" />
          </filter>
          <linearGradient id="paint0_linear_3_4" x1="-9.5" y1="100" x2="-9.5" y2="424" gradientUnits="userSpaceOnUse">
            <stop stop-color="#A9BCFF" />
            <stop offset="1" stop-color="#CECDFC" />
          </linearGradient>
        </defs>
      </svg>
    );
    return theme === 'dark' ? darkTheme : lightTheme;
  }

  const getCircleSvg = () => {
    const darkTheme = (
      <svg xmlns="http://www.w3.org/2000/svg" width="159" height="358" viewBox="0 0 159 358" fill="none" className=" fixed right-0 top-2 -z-5">
        <g filter="url(#filter0_f_45_347)">
          <circle cx="179" cy="179" r="79" fill="url(#paint0_linear_45_347)" />
        </g>
        <defs>
          <filter id="filter0_f_45_347" x="0" y="0" width="358" height="358" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_45_347" />
          </filter>
          <linearGradient id="paint0_linear_45_347" x1="179" y1="100" x2="179" y2="258" gradientUnits="userSpaceOnUse">
            <stop stop-color="#392A40" />
            <stop offset="1" stop-color="#24236A" />
          </linearGradient>
        </defs>
      </svg>
    );

    const lightTheme = (
      <svg xmlns="http://www.w3.org/2000/svg" width="159" height="358" viewBox="0 0 159 358" fill="none" className=" fixed right-0 top-2 -z-5">
        <g filter="url(#filter0_f_3_3)">
          <circle cx="179" cy="179" r="79" fill="url(#paint0_linear_3_3)" />
        </g>
        <defs>
          <filter id="filter0_f_3_3" x="0" y="0" width="358" height="358" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_3_3" />
          </filter>
          <linearGradient id="paint0_linear_3_3" x1="179" y1="100" x2="179" y2="258" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E3A9FF" />
            <stop offset="1" stop-color="#ABA9FF" />
          </linearGradient>
        </defs>
      </svg>
    );

    return theme === 'dark' ? darkTheme : lightTheme;
  };
  const deleteExpense = (index) => {
    const updatedExpenses = [...goodExpense];
    updatedExpenses.splice(index, 1);
    setGoodExpense(updatedExpenses);
  };

  const deleteBadExpense = (index) => {
    const updatedExpenses = [...badExpense];
    updatedExpenses.splice(index, 1);
    setBadExpense(updatedExpenses);
  };



  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme : "dark"; // Default to dark theme if no preference is saved
  };
  
  const [theme, setTheme] = useState(getInitialTheme());
  

  const calculateCategorySum = (expenses, category) => {
    return expenses
      .filter(expense => expense.category === category)
      .reduce((sum, expense) => sum + parseFloat(expense.amount), 0);
  };







  useEffect(() => {

    const fetchData = async () => {
      try {
        const today = new Date();


        const response = await axiosInstance.post('/expense/totalexpensedetail', {
          startDate,
          endDate: today.toISOString(),
        });
        console.log(response.data);

        // Assuming your API returns the data in the format { totalExpense, allExpenseData }
        const { totalExpense, allExpenseData } = response.data;

        const goodExpenses = allExpenseData.filter(expense => expense.expensetype === 'good' || expense.expensetype === 'Good');
        const badExpenses = allExpenseData.filter(expense => expense.expensetype === 'bad' || expense.expensetype === 'Bad');
        

        console.log(goodExpense);
        console.log(badExpense);

        if(totalExpense!=null){
          setLossAmount(totalExpense);
          setGoodExpense(goodExpenses);
          setBadExpense(badExpenses)
        }
        else{
          setLossAmount(0)
        }
        setIsLoading(false)
      } catch (error) {
        // If the request results in a 401 Unauthorized status, redirect to /#/accounts
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          // Handle other errors if needed
          console.error('Error fetching financial overview:', error);
        }
            }
    };
    
    fetchData();
  }, [startDate]);

  if (isLoading) {
    console.log(isLoading);
    return (
      <div className={`${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'} flex justify-center items-center h-screen`}>
      <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-black'}`}>Loading...</p>
    </div>
   );
  }
  return (
    <div className={`relative min-h-screen w-full p-6 flex flex-col gap-4 ${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'}`}>
    {getCircleSvg()}
    {getEllipseSvg()}
    <div className="flex justify-between">
      <h1 className={`text-4xl lg:text-6xl font-rubik mb-7 font-bold ${theme === 'dark' ? 'text-gray-300' : 'text-black-300'}`}>
        Expense
      </h1>

      <input
        type="date"
        className="h-6 bg-transparent text-slate-400 border border-slate-700 border-solid p-3 font-rubik rounded-md pt-5 pb-5 z-50"
        value={startDate} // Set the value of the input to startDate
        onChange={(e) => setStartDate(e.target.value)} // Update startDate when input value changes
      /> 
            </div>
      <div className="w-full ">
        <div className="LoosRed text-white rounded-3xl p-6 md:p-10 flex flex-col justify-center items-start md:h-36 font-rubik h-44 relative overflow-hidden ">
          <div className=" bg-slate-50 bg-opacity-10 w-28 h-28 rounded-full absolute  -top-4 right-5 -z-0 "></div>
          <div className=" bg-slate-50 bg-opacity-10 w-40 h-40 rounded-full absolute  -top-1 -right-10"></div>
          <p className={`font-light mb-4  ${theme === 'dark' ? 'text-slate-300' : 'text-black-300'}`}>Your Total Loss</p>
          <h3 className="font-bold text-5xl md:text-4xl lg:text-6xl">
            <span className="font-poppins text-4xl md:text-2xl mr-1">₹</span>
            {lossAmount}
          </h3>
        </div>
      </div>
      <div className="border border-blue-600 border-solid text-blue-200 w-full rounded-2xl p-6 lg:p-10 flex flex-col justify-center items-center lg:h-36 relative">
        <Lossgraph goodExpenses={goodExpense} badExpenses={badExpense} />
      </div>
      <div className=" text-blue-200 w-full rounded-3xl p-6 lg:p-10 flex flex-col justify-center items-start lg:h-36 relative">
        <div className="flex justify-between w-full  text-md font-rubik">
          <button className={`font-light mb-4  ${theme === 'dark' ? 'text-slate' : 'text-black'}`} onClick={() => toggleExpenses("good")}>Good Expense</button>
          <button className={`font-light mb-4  ${theme === 'dark' ? 'text-slate' : 'text-black'}`} onClick={() => toggleExpenses("bad")}>Bad Expense</button>
          <div
            className="absolute  ml-10 mt-9 left-0 w-1/3 h-1 bg-blue-400 rounded-full transition-transform duration-300 "
            style={{
              transform: showBadExpenses ? "translateX(145%)" : "translateX(-15%)",
            }}
          ></div>
        </div>

        {showGoodExpenses && (
          <div className="w-full mt-7 -z-0">
            {Array.from(new Set(goodExpense.map(expense => expense.category))).map((category, index) => {
              const categoryExpenses = goodExpense.filter(expense => expense.category === category);
              const categorySum = calculateCategorySum(goodExpense, category);

              return (
                <div key={index} className="w-full border border-blue-200 border-solid bg-slate-900 rounded-2xl text-white p-5 relative mb-5 pb-8">
                  <div className="flex w-full gap-5">
                    <Link to={`/expenses?category=${category}&type=Good`} className="bg-slate-800 w-12 h-12 rounded-full flex justify-center items-center">
                      <span>
                        <svg
                          width="512"
                          height="512"
                          viewBox="0 0 1024 1024"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                        >
                          <path
                            fill="currentColor"
                            d="M256 640v192h640V384H768v-64h150.976c14.272 0 19.456 1.472 24.64 4.288a29.056 29.056 0 0 1 12.16 12.096c2.752 5.184 4.224 10.368 4.224 24.64v493.952c0 14.272-1.472 19.456-4.288 24.64a29.056 29.056 0 0 1-12.096 12.16c-5.184 2.752-10.368 4.224-24.64 4.224H233.024c-14.272 0-19.456-1.472-24.64-4.288a29.056 29.056 0 0 1-12.16-12.096c-2.688-5.184-4.224-10.368-4.224-24.576V640z"
                          />
                          <path
                            fill="currentColor"
                            d="M768 192H128v448h640zm64-22.976v493.952c0 14.272-1.472 19.456-4.288 24.64a29.056 29.056 0 0 1-12.096 12.16c-5.184 2.752-10.368 4.224-24.64 4.224H105.024c-14.272 0-19.456-1.472-24.64-4.288a29.056 29.056 0 0 1-12.16-12.096C65.536 682.432 64 677.248 64 663.04V169.024c0-14.272 1.472-19.456 4.288-24.64a29.056 29.056 0 0 1 12.096-12.16C85.568 129.536 90.752 128 104.96 128h685.952c14.272 0 19.456 1.472 24.64 4.288a29.056 29.056 0 0 1 12.16 12.096c2.752 5.184 4.224 10.368 4.224 24.64z"
                          />
                          <path
                            fill="currentColor"
                            d="M448 576a160 160 0 1 1 0-320a160 160 0 0 1 0 320m0-64a96 96 0 1 0 0-192a96 96 0 0 0 0 192"
                          />
                        </svg>
                      </span>
                    </Link>
                    <div>
                      <Link to={categoryExpenses[0].to}><p className="font-rubik text-lg">{category}</p></Link>
                    </div>
                    <div className="absolute right-3 top-3">
                      <svg
                        width="512"
                        height="512"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 text-slate-500"
                        onClick={() => deleteExpense(categoryExpenses[0].id)}
                      >
                        <path
                          fill="currentColor"
                          d="M17.414 16L24 9.414L22.586 8L16 14.586L9.414 8L8 9.414L14.586 16L8 22.586L9.414 24L16 17.414L22.586 24L24 22.586z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="w-full mt-3 flex justify-between items-center gap-2">
                    <ProgressBar
                      completed='100'
                      width="160px"
                      height="18px"
                      bgColor="#4ade80"
                    />
                    <p className="font-poppins"><span className="text-xs font-thin"></span>₹{categorySum}<span className="text-xs font-thin"></span></p>
                  </div>
                </div>
              );
            })}
          </div>
        )}



{showBadExpenses && (
  <div className="w-full mt-7 -z-0">
    {Array.from(new Set(badExpense.map(expense => expense.category))).map((category, index) => {
      const categoryExpenses = badExpense.filter(expense => expense.category === category);
      const categorySum = calculateCategorySum(badExpense, category);
  

      return (
        <div key={index} className="w-full border border-blue-200 border-solid bg-slate-900 rounded-2xl text-white p-5 relative mb-5 pb-8">
          <div className="flex w-full gap-5">
            <Link to={`/expenses?category=${category}&type=Bad`} className="bg-slate-800 w-12 h-12 rounded-full flex justify-center items-center">
              <span>
                <svg
                  width="512"
                  height="512"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                >
                  <path
                    fill="currentColor"
                    d="M256 640v192h640V384H768v-64h150.976c14.272 0 19.456 1.472 24.64 4.288a29.056 29.056 0 0 1 12.16 12.096c2.752 5.184 4.224 10.368 4.224 24.64v493.952c0 14.272-1.472 19.456-4.288 24.64a29.056 29.056 0 0 1-12.096 12.16c-5.184 2.752-10.368 4.224-24.64 4.224H233.024c-14.272 0-19.456-1.472-24.64-4.288a29.056 29.056 0 0 1-12.16-12.096c-2.688-5.184-4.224-10.368-4.224-24.576V640z"
                  />
                  <path
                    fill="currentColor"
                    d="M768 192H128v448h640zm64-22.976v493.952c0 14.272-1.472 19.456-4.288 24.64a29.056 29.056 0 0 1-12.096 12.16c-5.184 2.752-10.368 4.224-24.64 4.224H105.024c-14.272 0-19.456-1.472-24.64-4.288a29.056 29.056 0 0 1-12.16-12.096C65.536 682.432 64 677.248 64 663.04V169.024c0-14.272 1.472-19.456 4.288-24.64a29.056 29.056 0 0 1 12.096-12.16C85.568 129.536 90.752 128 104.96 128h685.952c14.272 0 19.456 1.472 24.64 4.288a29.056 29.056 0 0 1 12.16 12.096c2.752 5.184 4.224 10.368 4.224 24.64z"
                  />
                  <path
                    fill="currentColor"
                    d="M448 576a160 160 0 1 1 0-320a160 160 0 0 1 0 320m0-64a96 96 0 1 0 0-192a96 96 0 0 0 0 192"
                  />
                </svg>
              </span>
            </Link>
            <div>
              <Link to={categoryExpenses[0].to}><p className="font-rubik text-lg">{category}</p></Link>
            </div>
            <div className="absolute right-3 top-3">
              <svg
                width="512"
                height="512"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 text-slate-500"
                onClick={() => deleteExpense(categoryExpenses[0].id)}
              >
                <path
                  fill="currentColor"
                  d="M17.414 16L24 9.414L22.586 8L16 14.586L9.414 8L8 9.414L14.586 16L8 22.586L9.414 24L16 17.414L22.586 24L24 22.586z"
                />
              </svg>
            </div>
          </div>
          <div className="w-full mt-3 flex justify-between items-center gap-2">
            <ProgressBar
              completed='50'
              width="160px"
              height="18px"
              bgColor="red"
            />
            <p className="font-poppins"><span className="text-xs font-thin"></span>₹{categorySum}<span className="text-xs font-thin"></span></p>
          </div>
        </div>
      );
    })}
  </div>
)}

      </div>
    </div>
  );
}

export default Loss;
