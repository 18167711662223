import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { axiosInstance } from "../../API/authapi.tsx";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import CreatableSelect from "react-select/creatable";

function AddCustomer() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [status, setStatus] = useState("");
  const [from,setFrom]=useState([])
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false); // New state for loading


  useEffect(() => {

    const fetchData = async () => {
      try {


        const response = await axiosInstance.post('/customer/getcategory');
        console.log(response.data);
        const {data} = response
        console.log(data);
       
        setFrom(data.map((cat) => ({ value: cat, label: cat })))

       
      } catch (error) {
        // If the request results in a 401 Unauthorized status, redirect to /#/accounts
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          // Handle other errors if needed
          console.error('Error fetching financial overview:', error);
        }
            }
    };
    
    fetchData();
  }, []);





  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: state.isFocused ? '1px solid #93C5FD' : '1px solid #D1D5DB',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #93C5FD',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9CA3AF',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',  // Set the text color to white
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',  // Set the selected option text color to white
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#6B7280' : 'transparent',
      color: state.isFocused ? '#F3F4F6' : '#4B5563',
    }),
  };

  const handleCreateCategory = (newCategory) => {
    const newOption = { value: newCategory, label: newCategory };
    setSelectedCategory(newOption);
  };


  const handleAddCustomer = () => {
    // Add validation logic
    if (!name || !phoneNumber || !status || !from) {
      toast.error("Please fill out all fields and select valid options");
      return;
    }

    // Disable the button during the request
    setLoading(true);

    // Create an object with the data to send in the request
    const customerData = {
      name,
      number: parseFloat(phoneNumber),
      status,
      from:selectedCategory.value,
    };

    // Make an Axios request
    axiosInstance
      .post("/customer/addcustomer", customerData)
      .then((response) => {
        console.log(response);
        // Handle success
        toast.success("Customer added successfully");
        // You can also reset the form or navigate to another page upon success
      })
      .catch((error) => {
        // Handle error
        toast.error("Error adding Customer");
      })
      .finally(() => {
        // Enable the button after the request is completed
        setLoading(false);
      });
  };

  return (
    <div className="relative w-full p-10 flex flex-col gap-4 bg-slate-900 pb-20 h-full font-rubik min-h-screen z-10">
      <div
        aria-hidden="true"
        className="fixed  w-16 h-16 rounded-full bg-gradient-to-b from-blue-200 to-purple-200 blur-3xl mx-auto scale-y-150  top-60 right-0 -z-0"
      ></div>

      <div
        aria-hidden="true"
        className="fixed  w-24 h-16 rounded-full bg-gradient-to-b from-blue-500 to-purple-600 blur-3xl mx-auto scale-y-150  bottom-60 left-0 -z-0"
      ></div>
      <div>
        <h1 className="text-4xl lg:text-6xl font-rubik mb-7 font-bold text-gray-300 ">
          Add Customer
        </h1>
      </div>

      <div className="relative group rounded-lg w-full bg-opacity-20 flex flex-col gap-6">
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Customer Name</h2>
          <input
            name=""
            id=""
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Enter Name"
          />
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Phone number</h2>
          <input
            name=""
            id=""
            type="number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Enter Phone number"
          />
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Reference</h2>
          <CreatableSelect
            options={from}
            isClearable
            styles={customStyles}
            onChange={(selectedOption) => setSelectedCategory(selectedOption)}
            onCreateOption={handleCreateCategory}
            placeholder="Select or type a Reference"
            isSearchable
            value={selectedCategory}
          />
        </div>


        <div>
          {/* Allow users to input a custom category */}

        </div>
        {/* <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Status</h2>
          <select
            name=""
            id=""
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Select Credit type"
          >
            <option className="bg-neutral-700 w-1/2 text-sm">Select Type</option>
            <option className="bg-neutral-700">On Hold</option>
            <option className="bg-neutral-700">On Progress</option>
            <option className="bg-neutral-700">Cancelled</option>
            <option className="bg-neutral-700">Completed</option>
            <option className="bg-neutral-700">Smm</option>
          </select>
        </div>       */}
          <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Status</h2>
          <select
            name=""
            id=""
            value={status} 
            onChange={(e) => setStatus(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Select Status type"
          >
            <option className="bg-neutral-700 w-1/2 text-sm">Select Status</option>
            <option className="bg-neutral-700">On Hold</option>
            <option className="bg-neutral-700">On Progress</option>
            <option className="bg-neutral-700">Cancelled</option>
            <option className="bg-neutral-700">Completed</option>
          </select>
        </div>

        <button
          onClick={handleAddCustomer}
          disabled={loading}
          className={`flex gap-3 cursor-pointer text-white font-semibold bg-gradient-to-r from-purple-600 to-blue-900 px-7 py-3 rounded-lg border border-gray-600 hover:scale-105 duration-200 hover:text-gray-500 hover:border-gray-800 hover:from-slate-800 hover:to-gray-900 justify-center items-center bg-opacity-10 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {loading ? "Adding..." : "Add"}
        </button>
      </div>
    </div>
  );

}

export default AddCustomer;
