import React from 'react';

const DeviceUnavailableScreen = ({ message }) => {
  return (
    <div className='bg-gray flex justify-center items-center h-screen'>
    <p className='text-gray-300'>Device Not Supported. <br/></p>
    <p className='text-gray-300'> {message}</p>
  </div>
  );
};

export default DeviceUnavailableScreen;
