import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../API/authapi.tsx";

const Navbar = () => {
  const [selected, setSelected] = useState(null);
  const location = useLocation();
  const navigate=useNavigate()


  const handleSelect = (option) => {
    setSelected(option);
  };

  const handleLogout =async () => {
    // Clear JWT_token cookie

    localStorage.removeItem('JWT_token');
    window.location.reload();

    navigate('/accounts')


    // Redirect to /accounts using navigate 
  };

  useEffect(() => {
    // Extract the first segment of the path (e.g., 'home' from '/home')
    const path = location.pathname.split('/')[1];
    setSelected(path);
  }, [location.pathname]);
const isManagementPage = location.pathname.includes("/management");


const handleGoBack = () => {
  window.history.back(); // Go back to the previous page
};

  const renderHighlight = (option) => {
    if (selected === option) {
      return (
        <div
          className={`absolute w-20 h-14 bg-blue-400 rounded-full -mt-3 shadow-md`}
        ></div>
      );
    }
    return null;
  };

  return (
    <div className="flex justify-center relative z-50">
      <div className="fixed bottom-3 w-8/12 bg-blue-500 p-4 flex justify-around items-center rounded-full">
      <button
          className="flex flex-col items-center relative"
          onClick={handleGoBack}
        >
                 {renderHighlight("back")}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="w-10 h-10 z-20"
          >
            <path
              fill="#fff"
              d="M20 11H7.414l2.293-2.293a1 1 0 1 0-1.414-1.414l-4 4a1 1 0 0 0 0 1.414l4 4a1 1 0 0 0 1.414-1.414L7.414 13H20a1 1 0 0 0 0-2z"
            />
          </svg>
        </button>
        <Link
          to={"/"}
          className="flex flex-col items-center relative"
          onClick={() => handleSelect("home")}
        >
          {renderHighlight("home")}
          <svg
            width="511.51"
            height="510"
            viewBox="0 0 1024 1025"
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-8 z-20"
          >
            <path
              fill="#fff"
              d="M1004.5 556.5Q985 576 957.5 576T911 557l-15-15v419q0 26-18.5 45t-45.5 19H640V737q0-13-9.5-22.5T608 705H416q-13 0-22.5 9.5T384 737v288H192q-27 0-45.5-19T128 961V542l-15 15q-19 19-46.5 19t-47-19.5t-19.5-47T19 463L463 19q20-20 49-19q29-1 49 19l444 444q19 19 19 46.5t-19.5 47"
            />
          </svg>
        </Link>
        {/* <Link
          to={"/accounts"}
          className="flex flex-col items-center relative"
          onClick={() => handleSelect("accounts")}
        >
          {renderHighlight("accounts")}
          <svg
            width="458.67"
            height="512"
            viewBox="0 0 344 384"
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-8 z-20"
          >
            <path
              fill="#fff"
              d="M170.5 192q-35.5 0-60.5-25t-25-60.5T110 46t60.5-25T231 46t25 60.5t-25 60.5t-60.5 25m0 43q31.5 0 69.5 9t69.5 29.5T341 320v43H0v-43q0-26 31.5-46.5T101 244t69.5-9"
            />
          </svg>
        </Link> */}
        <Link
          to={isManagementPage ? "/management/alltransactions" : "/alltransactions"}
          className="flex flex-col items-center relative"
          onClick={() => handleSelect("alltransactions")}
        >
          {renderHighlight("alltransactions")}
          <svg fill="#fff" width="35" className=" z-20"
            height="35" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M17.0020048,13 C17.5542895,13 18.0020048,13.4477153 18.0020048,14 C18.0020048,14.5128358 17.6159646,14.9355072 17.1186259,14.9932723 L17.0020048,15 L5.41700475,15 L8.70911154,18.2928932 C9.0695955,18.6533772 9.09732503,19.2206082 8.79230014,19.6128994 L8.70911154,19.7071068 C8.34862757,20.0675907 7.78139652,20.0953203 7.38910531,19.7902954 L7.29489797,19.7071068 L2.29489797,14.7071068 C1.69232289,14.1045317 2.07433707,13.0928192 2.88837381,13.0059833 L3.00200475,13 L17.0020048,13 Z M16.6128994,4.20970461 L16.7071068,4.29289322 L21.7071068,9.29289322 C22.3096819,9.8954683 21.9276677,10.9071808 21.1136309,10.9940167 L21,11 L7,11 C6.44771525,11 6,10.5522847 6,10 C6,9.48716416 6.38604019,9.06449284 6.88337887,9.00672773 L7,9 L18.585,9 L15.2928932,5.70710678 C14.9324093,5.34662282 14.9046797,4.77939176 15.2097046,4.38710056 L15.2928932,4.29289322 C15.6533772,3.93240926 16.2206082,3.90467972 16.6128994,4.20970461 Z"></path> </g></svg>


        </Link>
        <Link
        
          className="flex flex-col items-center relative"
          onClick={() => handleLogout()}

        >
          {renderHighlight("logout")}
          <svg width="35" className=" z-20"
            height="35" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.75 9.874C11.75 10.2882 12.0858 10.624 12.5 10.624C12.9142 10.624 13.25 10.2882 13.25 9.874H11.75ZM13.25 4C13.25 3.58579 12.9142 3.25 12.5 3.25C12.0858 3.25 11.75 3.58579 11.75 4H13.25ZM9.81082 6.66156C10.1878 6.48991 10.3542 6.04515 10.1826 5.66818C10.0109 5.29121 9.56615 5.12478 9.18918 5.29644L9.81082 6.66156ZM5.5 12.16L4.7499 12.1561L4.75005 12.1687L5.5 12.16ZM12.5 19L12.5086 18.25C12.5029 18.25 12.4971 18.25 12.4914 18.25L12.5 19ZM19.5 12.16L20.2501 12.1687L20.25 12.1561L19.5 12.16ZM15.8108 5.29644C15.4338 5.12478 14.9891 5.29121 14.8174 5.66818C14.6458 6.04515 14.8122 6.48991 15.1892 6.66156L15.8108 5.29644ZM13.25 9.874V4H11.75V9.874H13.25ZM9.18918 5.29644C6.49843 6.52171 4.7655 9.19951 4.75001 12.1561L6.24999 12.1639C6.26242 9.79237 7.65246 7.6444 9.81082 6.66156L9.18918 5.29644ZM4.75005 12.1687C4.79935 16.4046 8.27278 19.7986 12.5086 19.75L12.4914 18.25C9.08384 18.2892 6.28961 15.5588 6.24995 12.1513L4.75005 12.1687ZM12.4914 19.75C16.7272 19.7986 20.2007 16.4046 20.2499 12.1687L18.7501 12.1513C18.7104 15.5588 15.9162 18.2892 12.5086 18.25L12.4914 19.75ZM20.25 12.1561C20.2345 9.19951 18.5016 6.52171 15.8108 5.29644L15.1892 6.66156C17.3475 7.6444 18.7376 9.79237 18.75 12.1639L20.25 12.1561Z" fill="#ffffff"></path> </g></svg>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
