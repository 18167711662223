import React, { useState, useEffect } from "react";
import { DatePicker } from 'antd';
import Item from "antd/es/list/Item";
import { axiosInstance } from "../../API/authapi.tsx";
import { Link, useNavigate } from "react-router-dom";
const { RangePicker } = DatePicker;
// import 'ant/dist/antd.css'


function Credits() {
  const navigate = useNavigate();
  const [incomingCredit, setincomingCredit] = useState(250);
  const [outgoingCredit, setoutgoingCredit] = useState(530);
  const [showGoodCredits, setShowGoodCredits] = useState(true);
  const [showBadCredits, setShowBadCredits] = useState(false);
  const [goodcredits, setGoodcredits] = useState([]);

  const [badcredits, setBadcredits] = useState([]);
  const [dates, setDates] = useState([]); // Add state for start date
  const [endDate, setEndDate] = useState(""); // Add state for end date

  const toggleCredits = (type) => {
    setShowGoodCredits(type === "good");
    setShowBadCredits(type === "bad");
  };

  const deletecredits = (index) => {
    const updatedCredits = [...goodcredits];
    updatedCredits.splice(index, 1);
    setGoodcredits(updatedCredits);
  };

  const deleteBadcredits = (index) => {
    const updatedCredits = [...badcredits];
    updatedCredits.splice(index, 1);
    setBadcredits(updatedCredits);
  };
  const generateWhatsAppLink = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber
      .replace(/\s/g, "")
      .replace(/\+/g, "");
    return `https://wa.me/${formattedPhoneNumber}`;
  };

  const formatDueDate = (dueDate) => {
    if (!dueDate) return ""; // Return an empty string if dueDate is undefined or null

    const formattedDate = new Date(dueDate).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    return formattedDate;
  };

  const currentDate = new Date();

  // Calculate the first day of the current month
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Format the date as "YYYY-MM-DD" for the input value
  const initialStartDate = formatDate(firstDayOfMonth);

  // Function to format the date as "YYYY-MM-DD"
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based index
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [startDate, setStartDate] = useState(initialStartDate);


  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme : "dark"; // Default to dark theme if no preference is saved
  };

  const [theme, setTheme] = useState(getInitialTheme());
  const [isLoading, setIsLoading] = useState(true);


  const getEllipseSvg = () => {
    const darkTheme = (
      <svg width="253" height="494" viewBox="0 0 253 494" fill="none" xmlns="http://www.w3.org/2000/svg" className=" fixed left-0 bottom-5 -z-5">
        <g filter="url(#filter0_f_45_348)">
          <ellipse cx="-9.5" cy="262" rx="162.5" ry="162" fill="url(#paint0_linear_45_348)" />
        </g>
        <defs>
          <filter id="filter0_f_45_348" x="-272" y="0" width="525" height="524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_45_348" />
          </filter>
          <linearGradient id="paint0_linear_45_348" x1="-9.5" y1="100" x2="-9.5" y2="424" gradientUnits="userSpaceOnUse">
            <stop stop-color="#070E23" />
            <stop offset="1" stop-color="#2D303E" />
          </linearGradient>
        </defs>
      </svg>
    );
    const lightTheme = (
      <svg width="253" height="494" viewBox="0 0 253 494" fill="none" xmlns="http://www.w3.org/2000/svg" className=" fixed left-0 bottom-5 -z-5">
        <g filter="url(#filter0_f_3_4)">
          <ellipse cx="-9.5" cy="262" rx="162.5" ry="162" fill="url(#paint0_linear_3_4)" />
        </g>
        <defs>
          <filter id="filter0_f_3_4" x="-272" y="0" width="525" height="524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_3_4" />
          </filter>
          <linearGradient id="paint0_linear_3_4" x1="-9.5" y1="100" x2="-9.5" y2="424" gradientUnits="userSpaceOnUse">
            <stop stop-color="#A9BCFF" />
            <stop offset="1" stop-color="#CECDFC" />
          </linearGradient>
        </defs>
      </svg>
    );
    return theme === 'dark' ? darkTheme : lightTheme;
  }

  const getCircleSvg = () => {
    const darkTheme = (
      <svg xmlns="http://www.w3.org/2000/svg" width="159" height="358" viewBox="0 0 159 358" fill="none" className=" fixed right-0 top-2 -z-5">
        <g filter="url(#filter0_f_45_347)">
          <circle cx="179" cy="179" r="79" fill="url(#paint0_linear_45_347)" />
        </g>
        <defs>
          <filter id="filter0_f_45_347" x="0" y="0" width="358" height="358" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_45_347" />
          </filter>
          <linearGradient id="paint0_linear_45_347" x1="179" y1="100" x2="179" y2="258" gradientUnits="userSpaceOnUse">
            <stop stop-color="#392A40" />
            <stop offset="1" stop-color="#24236A" />
          </linearGradient>
        </defs>
      </svg>
    );

    const lightTheme = (
      <svg xmlns="http://www.w3.org/2000/svg" width="159" height="358" viewBox="0 0 159 358" fill="none" className=" fixed right-0 top-2 -z-5">
        <g filter="url(#filter0_f_3_3)">
          <circle cx="179" cy="179" r="79" fill="url(#paint0_linear_3_3)" />
        </g>
        <defs>
          <filter id="filter0_f_3_3" x="0" y="0" width="358" height="358" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_3_3" />
          </filter>
          <linearGradient id="paint0_linear_3_3" x1="179" y1="100" x2="179" y2="258" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E3A9FF" />
            <stop offset="1" stop-color="#ABA9FF" />
          </linearGradient>
        </defs>
      </svg>
    );

    return theme === 'dark' ? darkTheme : lightTheme;
  };


  useEffect(() => {


    const fetchData = async () => {
      try {
        const today = new Date();

        const response = await axiosInstance.post('/credit/totalcreditsdata', {
          startDate: startDate,
          endDate: today.toISOString(),
        });
        console.log(response.data);

        // Assuming your API returns the data in the format { totalExpense, allExpenseData }
        const { allCreditData, totalRecieveCredit, totalSentCredit } = response.data;

        const toRecieveCredit = await allCreditData.filter(credit => credit.category === 'torecieve')
        const toSentCredit = await allCreditData.filter(credit => credit.category === 'tosent');
        console.log(totalSentCredit);
        if (totalRecieveCredit === null) {
          setBadcredits(toSentCredit);
          setincomingCredit(0);
        } else {
          setBadcredits(toSentCredit);
          setincomingCredit(totalRecieveCredit);
        }

        if (totalSentCredit === null) {
          setGoodcredits(toRecieveCredit);
          setoutgoingCredit(0);
        } else {
          setGoodcredits(toRecieveCredit);
          setoutgoingCredit(totalSentCredit);
        }
        setIsLoading(false)

        // setDonationAmount(totalDonation);
        // setDonationData(allDonationData)

      } catch (error) {
        // If the request results in a 401 Unauthorized status, redirect to /#/accounts
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          // Handle other errors if needed
          console.error('Error fetching financial overview:', error);
        }
      }
    };
    fetchData();
  }, [startDate]); // Fetch data when startDate or endDate changes  \
  if (isLoading) {
    console.log(isLoading);
    return (
      <div className={`${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'} flex justify-center items-center h-screen`}>
        <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-black'}`}>Loading...</p>
      </div>

    );
  }
  return (
    <div className={`relative min-h-screen w-full p-6 flex flex-col gap-4 ${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'}`}>
      {getCircleSvg()}
      {getEllipseSvg()}
      <div className="flex justify-between">
        <h1 className={`text-4xl lg:text-6xl font-rubik mb-7 font-bold ${theme === 'dark' ? 'text-gray-300' : 'text-black-300'}`}>
          Credits
        </h1>

        <input
          type="date"
          className="h-6 bg-transparent text-slate-400 border border-slate-700 border-solid p-3 font-rubik rounded-md pt-5 pb-5 z-50"
          value={startDate} // Set the value of the input to startDate
          onChange={(e) => setStartDate(e.target.value)} // Update startDate when input value changes
        />
      </div>
      <div className="w-full ">
        <div className="ProfitGrenn text-white rounded-3xl p-6 md:p-10 flex flex-col justify-center items-start md:h-36 font-rubik h-25 relative overflow-hidden ">
          <div className=" bg-slate-50 bg-opacity-10 w-28 h-28 rounded-full absolute  -top-4 right-5 -z-0 "></div>
          <div className=" bg-slate-50 bg-opacity-10 w-40 h-40 rounded-full absolute  -top-1 -right-10"></div>
          <p className={`font-light mb-4  ${theme === 'dark' ? 'text-slate-300' : 'text-black-300'}`}>To Recieve</p>
          <h3 className="font-bold text-5xl md:text-4xl lg:text-6xl">
            <span className="font-poppins text-4xl md:text-2xl mr-1">₹</span>
            {incomingCredit}
          </h3>
        </div>
      </div>
      <div className="w-full ">
        <div className="LoosRed text-white rounded-3xl p-6 md:p-10 flex flex-col justify-center items-start md:h-36 font-rubik h-25 relative overflow-hidden ">
          <div className=" bg-slate-50 bg-opacity-10 w-28 h-28 rounded-full absolute  -top-4 right-5 -z-0 "></div>
          <div className=" bg-slate-50 bg-opacity-10 w-40 h-40 rounded-full absolute  -top-1 -right-10"></div>
          <p className={`font-light mb-4  ${theme === 'dark' ? 'text-slate-300' : 'text-black-300'}`}>To Sent</p>
          <h3 className="font-bold text-5xl md:text-4xl lg:text-6xl">
            <span className="font-poppins text-4xl md:text-2xl mr-1">₹</span>
            {outgoingCredit}
          </h3>
        </div>
      </div>

      <div className="text-blue-200 w-full rounded-3xl p-5 md:p-10 flex flex-col justify-center items-start md:h-36 relative">
        <div className="flex justify-between w-full  text-md font-rubik">
          <button className={`font-light mb-4 ml-5 ${theme === 'dark' ? 'text-slate' : 'text-black'}`} onClick={() => toggleCredits("good")}>To Recieve</button>
          <button className={`font-light mb-4 mr-10 ${theme === 'dark' ? 'text-slate' : 'text-black'}`} onClick={() => toggleCredits("bad")}>To Sent</button>
          <div
            className="absolute  ml-10 mt-9 left-0 w-1/3 h-1 bg-blue-400 rounded-full transition-transform duration-300 "
            style={{
              transform: showBadCredits ? "translateX(145%)" : "translateX(-15%)",
            }}
          ></div>
        </div>

        {showGoodCredits && (
          <div className="w-full mt-8">

            {goodcredits.map((credits, index) => (
              <Link key={index} to={`/credit/updatecredit/${credits._id}`}>

                <div
                  key={index}
                  className="w-full bg-green-800 bg-opacity-30 text-white relative font-rubik mb-5 border-2 border-green-700 p-5 rounded-lg"
                >
                  <div className="w-full flex justify-between items-center">
                    <p className="text-lg">{credits.type}</p>
                    <p>₹{credits.amount}</p>
                  </div>
                  <div className="flex w-full justify-between">
                    <p className="text-sm font-extralight text-slate-500">
                      due: {formatDueDate(credits.duedate)}
                    </p>
                    {credits.to && (
                      <a
                        href={generateWhatsAppLink(credits.to)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-green-500"
                      >
                        <svg
                          width="512"
                          height="512"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                        >
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977c0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162l-2.824.741l.753-2.753l-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188a7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73c-.205-.075-.354-.112-.504.112s-.58.729-.711.879s-.262.168-.486.056s-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393c.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666c-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008a.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387c.536.231.954.369 1.279.473c.537.171 1.026.146 1.413.089c.431-.064 1.327-.542 1.514-1.066c.187-.524.187-.973.131-1.067c-.056-.094-.207-.151-.43-.263"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}

        {showBadCredits && (
          <div className="w-full mt-8">
            {badcredits.map((credits, index) => (
              <Link key={index} to={`/credit/updatecredit/${credits._id}`}>

                <div
                  key={index}
                  className="w-full bg-red-800 bg-opacity-30 text-white relative font-rubik mb-5 border-2 border-red-700 p-5 rounded-lg"
                >
                  <div className="w-full flex justify-between items-center">
                    <p className="text-lg">{credits.type}</p>
                    <p>${credits.amount}</p>
                  </div>
                  <div className="flex w-full justify-between">
                    <p className="text-sm font-extralight text-slate-500">
                      due: {formatDueDate(credits.duedate)}
                    </p>
                    {credits.to && (
                      <a
                        href={generateWhatsAppLink(credits.to)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-green-500"
                      >
                        <svg
                          width="512"
                          height="512"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                        >
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977c0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162l-2.824.741l.753-2.753l-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188a7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73c-.205-.075-.354-.112-.504.112s-.58.729-.711.879s-.262.168-.486.056s-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393c.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666c-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008a.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387c.536.231.954.369 1.279.473c.537.171 1.026.146 1.413.089c.431-.064 1.327-.542 1.514-1.066c.187-.524.187-.973.131-1.067c-.056-.094-.207-.151-.43-.263"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    )}
                  </div>
                </div>
                </Link>
            ))}
              </div>
            )}
          </div>
    </div>
      );
}

      export default Credits;
