import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../API/authapi.tsx';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';


const Login = () => {
  const navigate = useNavigate(); 
  const [formData, setFormData] = useState({ 
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  useEffect(() => {

    const fetchData = async () => {
      try {
        const today = new Date();

        const response = await axiosInstance.post('/users/verifyuser');
        navigate('/')
      } catch (error) {
        // If the request results in a 401 Unauthorized status, redirect to /#/accounts
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          // Handle other errors if needed
          console.error('Error fetching financial overview:', error);
        }      }
    };
    fetchData();
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Make a POST request to your login endpoint
      toast.warning('Verifying...');
      const response = await axiosInstance.post('/users/login', formData);
  
      // Handle the response, e.g., store the token in local storage or redirect to another page
      console.log('Login successful', response.data);
      const { token } = response.data;
  
      // Store the token in localStorage
      localStorage.setItem('JWT_token', token);
  
      toast.success('Login successful!');
      window.location.href = '/';
    } catch (error) {
      toast.error('Username or Password Wrong');
      // Handle error, e.g., show an error message to the user
    }
  }; 

  return (
    <div className='pt-3 '>
      <h1 className="text-5xl lg:text-6xl font-rubik mb-7 font-bold text-gray-300 mt-5 ml-6">
        Accounts
      </h1>
      <div className="flex flex-col items-center mt-20 h-screen dark">
        <div className="w-11/12 max-w-md bg-gray-800 rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold text-gray-200 mb-4 font-rubik">Login</h2>
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <input
              placeholder="Email address"
              className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
              type="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
            <input
              placeholder="Password"
              className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <div className="flex items-center justify-between flex-wrap">
              <label className="text-sm text-gray-200 cursor-pointer" htmlFor="remember-me">
                <input className="mr-2" id="remember-me" type="checkbox" />
                Remember me
              </label>
              <a className="text-sm text-blue-500 hover:underline mb-0.5" href="#">
                Forgot password?
              </a>
              <p className="text-white mt-4">
                Don't have an account?{' '}
                <a className="text-sm text-blue-500 -200 hover:underline mt-4" href="/#/signup">
                  Signup
                </a>
              </p>
            </div>
            <button
              className="bg-gradient-to-r from-indigo-500 to-blue-500 text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
              type="submit"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
