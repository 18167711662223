import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../../API/authapi.tsx';
import { toast } from 'react-toastify';

function AddItem() {
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState(); // Changed initial state to 0
  const [price, setPrice] = useState(0); // Changed initial state to 0
  const [foods, setFoods] = useState([]);
  const [suggestions, setSuggestions] = useState([]); // State for suggestions
  const [suggestionList, setSuggestionList] = useState([])
  const [loading, setLoading] = useState(false); // New state for loading

  const totalPrice = foods.reduce((total, food) => total + (food.price * food.qty), 0).toFixed(2);
  // Sample array of suggestions with name, price, and quantity properties


  useEffect(() => {
    // Fetch suggestion list data from backend API
    async function fetchSuggestions() {
      try {
        const response = await axiosInstance.get('/product'); // Replace '/api/suggestions' with your actual API endpoint
        console.log(response.data);
        setSuggestionList(response.data); // Update suggestions state with fetched data
      } catch (error) {
        console.error('Error fetching suggestion list:', error);
      }
    }

    fetchSuggestions(); // Call the fetchSuggestions function when the component mounts
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  const handleRemoveFood = (index) => {
    const updatedFoods = [...foods];
    updatedFoods.splice(index, 1); // Remove the item at the specified index
    setFoods(updatedFoods);
  };
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme : "dark"; // Default to dark theme if no preference is saved
  };

  const [theme, setTheme] = useState(getInitialTheme());
  const [isLoading, setIsLoading] = useState(false);


  const getEllipseSvg = () => {
    const darkTheme = (
      <svg width="253" height="494" viewBox="0 0 253 494" fill="none" xmlns="http://www.w3.org/2000/svg" className=" fixed left-0 bottom-5 -z-5">
        <g filter="url(#filter0_f_45_348)">
          <ellipse cx="-9.5" cy="262" rx="162.5" ry="162" fill="url(#paint0_linear_45_348)" />
        </g>
        <defs>
          <filter id="filter0_f_45_348" x="-272" y="0" width="525" height="524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_45_348" />
          </filter>
          <linearGradient id="paint0_linear_45_348" x1="-9.5" y1="100" x2="-9.5" y2="424" gradientUnits="userSpaceOnUse">
            <stop stop-color="#070E23" />
            <stop offset="1" stop-color="#2D303E" />
          </linearGradient>
        </defs>
      </svg>
    );
    const lightTheme = (
      <svg width="253" height="494" viewBox="0 0 253 494" fill="none" xmlns="http://www.w3.org/2000/svg" className=" fixed left-0 bottom-5 -z-9">
        <g filter="url(#filter0_f_3_4)">
          <ellipse cx="-9.5" cy="262" rx="162.5" ry="162" fill="url(#paint0_linear_3_4)" />
        </g>
        <defs>
          <filter id="filter0_f_3_4" x="-272" y="0" width="525" height="524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_3_4" />
          </filter>
          <linearGradient id="paint0_linear_3_4" x1="-9.5" y1="100" x2="-9.5" y2="424" gradientUnits="userSpaceOnUse">
            <stop stop-color="#A9BCFF" />
            <stop offset="1" stop-color="#CECDFC" />
          </linearGradient>
        </defs>
      </svg>
    );
    return theme === 'dark' ? darkTheme : lightTheme;
  }

  const getCircleSvg = () => {
    const darkTheme = (
      <svg xmlns="http://www.w3.org/2000/svg" width="159" height="358" viewBox="0 0 159 358" fill="none" className=" fixed right-0 top-2 -z-5">
        <g filter="url(#filter0_f_45_347)">
          <circle cx="179" cy="179" r="79" fill="url(#paint0_linear_45_347)" />
        </g>
        <defs>
          <filter id="filter0_f_45_347" x="0" y="0" width="358" height="358" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_45_347" />
          </filter>
          <linearGradient id="paint0_linear_45_347" x1="179" y1="100" x2="179" y2="258" gradientUnits="userSpaceOnUse">
            <stop stop-color="#392A40" />
            <stop offset="1" stop-color="#24236A" />
          </linearGradient>
        </defs>
      </svg>
    );

    const lightTheme = (
      <svg xmlns="http://www.w3.org/2000/svg" width="159" height="358" viewBox="0 0 159 358" fill="none" className=" fixed right-0 top-2 -z-5">
        <g filter="url(#filter0_f_3_3)">
          <circle cx="179" cy="179" r="79" fill="url(#paint0_linear_3_3)" />
        </g>
        <defs>
          <filter id="filter0_f_3_3" x="0" y="0" width="358" height="358" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_3_3" />
          </filter>
          <linearGradient id="paint0_linear_3_3" x1="179" y1="100" x2="179" y2="258" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E3A9FF" />
            <stop offset="1" stop-color="#ABA9FF" />
          </linearGradient>
        </defs>
      </svg>
    );

    return theme === 'dark' ? darkTheme : lightTheme;
  };
  const handleInputChange = (value) => {
    setItemName(value);
    const filteredSuggestions = suggestionList.filter(suggestion =>
      suggestion.name.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  // Function to handle selection of a suggestion
  const handleSuggestionClick = (selectedSuggestion) => {
    setItemName(selectedSuggestion.name);
    setQuantity(1)
    setPrice(selectedSuggestion.price)
    setSuggestions([]); // Clear suggestions
  };

  const handleAddFood = () => {
    console.log(quantity);
    if (itemName.trim() !== '' && quantity > 0) {
      setFoods([
        ...foods,
        {
          name: itemName,
          qty: quantity,
          price: price
        }
      ]);
      setItemName('');
      setQuantity(''); // Reset quantity to 0 after adding the item
    }
  };

  const handleIncrementQuantity = (index) => {
    const updatedFoods = [...foods];
    updatedFoods[index].qty++;
    setFoods(updatedFoods);
  };

  const handleDecrementQuantity = (index) => {
    const updatedFoods = [...foods];
    if (updatedFoods[index].qty > 0) {
      updatedFoods[index].qty--;
      setFoods(updatedFoods);
    }
  };

  const [totalAmount, setTotalAmount] = useState(0); // State to store total amount

  // Function to calculate total amount
  const calculateTotalAmount = () => {
    return foods.reduce((total, food) => total + (food.price * food.qty), 0);
  };



  // Function to handle adding an order
  const handleAddOrder = async () => {
    try {
      setLoading(true);
      // Calculate total amount before making the request
      const total = calculateTotalAmount();

      // Make the POST request to add the invoice
      const response = await axiosInstance.post('/invoice/add', {
        items: foods,
        total,
        // other fields like tax, grandTotal, etc.
      });

      // Reset foods array after successful addition
      setFoods([]);
      // Update total amount in state
      setTotalAmount(0);

      toast.success('Invoice added successfully');
      //  state or perform any necessary actions after adding the invoice
    } catch (error) {
      console.log(error);
      toast.error('Error adding invoice');
      // Handle error, display error message to user, etc.
    }
  }


  return (
    <div className={`relative min-h-screen w-full p-6 flex flex-col gap-4 ${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'}`}>
      {getCircleSvg()}
      {getEllipseSvg()}

      <h1 className={`text-4xl lg:text-6xl font-rubik mb-7 font-bold ${theme === 'dark' ? 'text-gray-300' : 'text-black-200'}`}>
        Add Item
      </h1>
      <div className="w-full ">
        <div className="flex justify-between">
          <input
            type="text"
            value={itemName}
            onChange={(e) => handleInputChange(e.target.value)}
            className="w-2/4 bg-slate-900 h-10 rounded-lg border-slate-500 border-2 pl-3 text-white"
            placeholder="Item name"
          />
          {/* Render suggestions as a dropdown */}
          {suggestions.length > 0 && (
            <ul className="bg-white text-black absolute mt-1 w-2/4 rounded-md shadow-lg z-10">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="py-1 px-3 cursor-pointer hover:bg-gray-200"
                >
                  {suggestion.name} - Price: ₹
                  {suggestion.price}
                </li>
              ))}
            </ul>
          )}
          <input
            type="text"
            value={quantity} // Use value prop to bind to state
            onChange={(e) => setQuantity(e.target.value)}
            className="w-1/4 bg-slate-900 h-10 rounded-lg border-slate-500 border-2 pl-3 text-white z-50"
            placeholder="Qty"
          />
          <button
            className="bg-emerald-500 h-10 w-10 rounded-lg z-50"
            onClick={handleAddFood}
          >
            +
          </button>
        </div>
        <div className="mt-5">
          {foods.map((food, index) => (
            <div key={index} className="w-full bg-black rounded-2xl opacity-70 p-6 flex justify-between mt-2 relative">
              <div className="w-12 h-12 bg-gray-800 rounded-full flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="200"
                  viewBox="0 0 24 24"
                  className="w-6 h-6 text-slate-200"
                >
                  <path
                    fill="currentColor"
                    d="M18.06 23h1.66c.84 0 1.53-.65 1.63-1.47L23 5.05h-5V1h-1.97v4.05h-4.97l.3 2.34c1.71.47 3.31 1.32 4.27 2.26c1.44 1.42 2.43 2.89 2.43 5.29V23M1 22v-1h15.03v1c0 .54-.45 1-1.03 1H2c-.55 0-1-.46-1-1m15.03-7C16.03 7 1 7 1 15h15.03M1 17h15v2H1v-2Z"
                  />
                </svg>
              </div>
              <div className="text-white flex flex-row justify-between w-9/12 ">
                <div className="w-4/6">
                  <p className="text-xl font-semibold">{food.name}</p>
                  <div className="flex flex-row g-5 w-3/5 justify-between">
                    <button
                      className="text-white bg-slate-900 rounded-full w-5 h-5 flex justify-center items-center text-xs"
                      onClick={() => handleDecrementQuantity(index)}
                    >
                      -
                    </button>
                    <p className="text-slate-400 text-sm">{food.qty} No.s</p>
                    <button
                      className="text-white bg-slate-900 rounded-full w-5 h-5 flex justify-center items-center text-xs "
                      onClick={() => handleIncrementQuantity(index)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div>
                  <p className="text-xl font-semibold">
                  ₹ {(food.price * food.qty).toFixed(2)}
                  </p>

                  {/* Add Remove button */}
                  <button
                    className="absolute bottom-2 right-2 bg-red-500 text-white px-1 py-0 rounded-md "
                    onClick={() => handleRemoveFood(index)}
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='relative'>
          <div className={` w-full border-2  border-slate-600  rounded-lg mt-5 flex p-2 justify-between pl-5 pr-5 ${theme === 'dark' ? 'text-gray-300' : 'text-black-300'}`}>
            <p>Total</p>
            <p>₹ {(foods.reduce((total, food) => total + (food.price * food.qty), 0)).toFixed(2)}</p>
          </div>

          <button onClick={handleAddOrder}
            disabled={loading}
            className={`w-full bg-emerald-400 h-12 rounded-lg border-2 border-emerald-600 mt-5 text-white font-semibold ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
            {loading ? "Adding..." : "Add Order"}
          </button>
        </div>

      </div>
    </div>
  );
}

export default AddItem;
