import React, { Component } from "react";
import Chart from "react-apexcharts";

class Lossgraph extends Component {
  render() {
    const { goodExpenses, badExpenses } = this.props;

    console.log(goodExpenses);

    if (!goodExpenses && !badExpenses) {
      // If both good and bad expenses are not present, you may want to render some default content or a message.
      return (
        <div className="app">
          <p>No expense data available.</p>
        </div>
      );
    }

    const options = {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: goodExpenses ? goodExpenses.map(expense => expense.category) : []
      },
       colors: ["#4ade80", "#ff0000"],
    };

    const series = [
      {
        name: "Good Expense",
        data: goodExpenses ? goodExpenses.map(expense => parseFloat(expense.amount)) : []
      }
    ];

    if (badExpenses) {
      // If bad expenses are present, add a series for bad expenses
      series.push({
        name: "Bad Expense",
        data: badExpenses.map(expense => parseFloat(expense.amount))
      });
    }

    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart mr-4">
            <Chart options={options} series={series} type="line" width="325" />
          </div>
        </div>
      </div> 
    );
  }
}

export default Lossgraph;
