import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { axiosInstance } from "../API/authapi.tsx";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { DatePicker, Modal } from "antd";
import CreatableSelect from "react-select/creatable";


function AddWork() {
  const navigate = useNavigate();
  const [workName, setWorkName] = useState(""); // Changed variable name from Work to workName
  const [amount, setAmount] = useState(""); // Changed variable name from amount to amount
  const [amountPaid, setAmountPaid] = useState(""); // Changed variable name from amountPaid to amountPaid
  const [dueDate, setDueDate] = useState(''); // Changed variable name from dueDate to dueDate
  const [customerID, setCustomerID] = useState(''); // Changed variable name from phoneNumber to customerID
  const [EmployeID, setEmployeID] = useState(''); // Changed variable name from phoneNumber to customerID
  const [customers, setCustomers] = useState([]); // Changed variable name from phoneNumber to customerID
  const [categoryData,setCategoryData] = useState([])
  const [category,setCategory] = useState([])
  const [Employe, setEmploye] = useState([]); // Changed variable name from phoneNumber to customerID
  const [status, setStatus] = useState(""); // Changed variable name from type to type
  const [payment, setPayment] = useState(""); // Changed variable name from payment to payment
  const [loading, setLoading] = useState(true); // New state for loading
  const [modalVisible, setModalVisible] = useState(false); // State for modal visibility
  const [selectedCategory, setSelectedCategory] = useState(null);



  useEffect(() => {


    const fetchCustomers = async () => {
      try {
        const today = new Date();

        const response = await axiosInstance.post('/customer/alldata', {
          startDate: "2024-02-01",
          endDate: today.toISOString(),
          status
        });
        console.log(response.data);

        // Assuming your API returns the data in the format { totalcustomer, allcustomerData }
        const { allCustomerData } = response.data;

       setCustomers(allCustomerData)
       setLoading(false)        
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);




  useEffect(() => {

    const fetchData = async () => {
      try {


        const response = await axiosInstance.post('/employe/getrole');
        console.log(response.data);
        const {data} = response
       
        setCategoryData(data)

       
      } catch (error) {
        // If the request results in a 401 Unauthorized status, redirect to /#/accounts
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          // Handle other errors if needed
          console.error('Error fetching financial overview:', error);
        }
            }
    };
    
    fetchData();
  }, []);

  useEffect(() => {

    const fetchData = async () => {
      try {


        const response = await axiosInstance.post('/employe/getemploye');
        console.log(response.data);
        const {data} = response
       
        setEmploye(data)

       
      } catch (error) {
        // If the request results in a 401 Unauthorized status, redirect to /#/accounts
        if (error.response && error.response.status === 401) {
          navigate('/accounts');
        } else {
          // Handle other errors if needed
          console.error('Error fetching financial overview:', error);
        }
            }
    };
    
    fetchData();
  }, []);
  
  


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: state.isFocused ? '1px solid #93C5FD' : '1px solid #D1D5DB',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #93C5FD',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9CA3AF',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',  // Set the text color to white
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',  // Set the selected option text color to white
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#6B7280' : 'transparent',
      color: state.isFocused ? '#F3F4F6' : '#4B5563',
    }),
  };



  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme : "dark"; // Default to dark theme if no preference is saved
  };

  const [theme, setTheme] = useState(getInitialTheme());


  const handleAddToProfit = () => {
    setModalVisible(false); // Close the modal
    addWorkToServer(true); // Add to profit
  };

  const handleCancel = () => {
    setModalVisible(false); // Close the modal without adding to profit
    addWorkToServer(false); // Proceed without linking to profit
  };

  const handleAddWork = () => {
    // Add validation logic

    // If amountPaid is greater than 0, prompt user to add to profit
    if (parseFloat(amountPaid) > 0) {
      setModalVisible(true); // Show the modal
      return; // Prevent further execution until user confirms
    }

    // Proceed to add work
    addWorkToServer(false);
  };

  // Function to add work to server
  const addWorkToServer = (linkToProfit) => {
    console.log( workName ,category ,amount ,customerID,Employe,dueDate,payment,status );
    if ( !workName || !category || !amount ||!customerID||!EmployeID||!dueDate||!payment||!status ) {
      toast.error("Please fill out all fields and select valid options");
      return;
    }
    setLoading(true);
    const workData = {
      workname: workName,
      amount: parseFloat(amount),
      amountPaid: parseFloat(amountPaid),
      category: category,
      customerId: customerID,
      employeeassigned: EmployeID,
      duedate: dueDate ? moment(dueDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
      payment,
      status,
      linkToProfit,
    };

    axiosInstance
      .post("/work/addwork", workData)
      .then((response) => {
        toast.success("Work added successfully");
      })
      .catch((error) => {
        toast.error("Error adding Work");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (loading) {
    return (
      <div className={`${theme === 'dark' ? 'dark:bg-gray' : 'bg-white'} flex justify-center items-center h-screen`}>
        <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-black'}`}>Loading...</p>
      </div>

    );
  }

  return (
    <div className="relative w-full p-10 flex flex-col gap-4 bg-slate-900 pb-20 h-full font-rubik min-h-screen z-10">
            <Modal
        title="Add to Profit"
        visible={modalVisible}
        onOk={handleAddToProfit}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
        destroyOnClose={true}
        theme="dark"
      ></Modal>
      <div
        aria-hidden="true"
        className="fixed  w-16 h-16 rounded-full bg-gradient-to-b from-blue-200 to-purple-200 blur-3xl mx-auto scale-y-150  top-60 right-0 -z-0"
      ></div>

      <div
        aria-hidden="true"
        className="fixed  w-24 h-16 rounded-full bg-gradient-to-b from-blue-500 to-purple-600 blur-3xl mx-auto scale-y-150  bottom-60 left-0 -z-0"
      ></div>
      <div>
        <h1 className="text-4xl lg:text-6xl font-rubik mb-7 font-bold text-gray-300 ">
          Add Work
        </h1>
      </div>

      <div className="relative group rounded-lg w-full bg-opacity-20 flex flex-col gap-6">
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Work Name</h2>
          <input
            name=""
            id=""
            value={workName}
            onChange={(e) => setWorkName(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Enter Work"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Amount</h2>
          <input
            name=""
            id=""
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Enter Amount"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Amount Paid</h2>
          <input
            name=""
            id=""
            type="number"
            value={amountPaid}
            onChange={(e) => setAmountPaid(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Enter Amount"
          />
        </div>
        <div>
        <h2 className="text-lg font-semibold text-gray-400 mb-2">Customer</h2>
        <select
          value={customerID}
          onChange={(e) => setCustomerID(e.target.value)}
          className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200 placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
        >
          <option value="" className="bg-neutral-700 w-1/2 text-sm">Select</option>
          {customers.map((customer) => (
            <option className="bg-neutral-700" key={customer._id} value={customer._id}>{customer.name}</option>
          ))}
        </select>
      </div>
      <div>
        <h2 className="text-lg font-semibold text-gray-400 mb-2">Employe Assigned</h2>
        <select
          value={EmployeID}
          onChange={(e) => setEmployeID(e.target.value)}
          className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200 placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
        >
          <option value="" className="bg-neutral-700 w-1/2 text-sm">Select</option>
          {Employe.map((username) => (
            <option className="bg-neutral-700" key={username._id} value={username._id}>{username.username}</option>
          ))}
        </select>
      </div>
      <div>
        <h2 className="text-lg font-semibold text-gray-400 mb-2">Work Category</h2>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200 placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
        >
          <option value="" className="bg-neutral-700 w-1/2 text-sm">Select</option>
          {categoryData.map((role) => (
            <option className="bg-neutral-700" key={role.role} value={role.role}>{role.role}</option>
          ))}
        </select>
      </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Status</h2>
          <select
            name=""
            id=""
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Select Work type"
          >
            <option className="bg-neutral-700 w-1/2 text-sm">Select</option>
            <option className="bg-neutral-700">On Hold</option>
            <option className="bg-neutral-700">On Progress</option>
            <option className="bg-neutral-700">Cancelled</option>
            <option className="bg-neutral-700">Completed</option>
          </select>
        </div>
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Due Date</h2>
          <DatePicker
            value={dueDate ? moment(dueDate, 'YYYY-MM-DD') : null}
            onChange={(date, dateString) => setDueDate(dateString)}
            className="my-custom-datepicker appearance-none hover:placeholder-shown:bg-red-800 relative text-red-100 bg-transparent ring-0 outline-none border border-neutral-500 text-red-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
          />
        </div>



        <div>
          {/* Allow users to input a custom category */}

        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Payment Method</h2>
          <select
            name=""
            id=""
            value={payment}
            onChange={(e) => setPayment(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Select Payment Method"
          >
            <option className="bg-neutral-700 w-1/2 text-sm">Select Payment Method</option>
            <option className="bg-neutral-700 ">Cash</option>
            <option className="bg-neutral-700">Bank</option>
          </select>
        </div>
        <button
          onClick={handleAddWork}
          disabled={loading}
          className={`flex gap-3 cursor-pointer text-white font-semibold bg-gradient-to-r from-purple-600 to-blue-900 px-7 py-3 rounded-lg border border-gray-600 hover:scale-105 duration-200 hover:text-gray-500 hover:border-gray-800 hover:from-slate-800 hover:to-gray-900 justify-center items-center bg-opacity-10 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {loading ? "Adding..." : "Add"}
        </button>
      </div>
    </div>
  );

}

export default AddWork;
