import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../API/authapi.tsx";
import CreatableSelect from "react-select/creatable";

function UpdateCustomer() {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState({});
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState("");
  const [from, setFrom] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);



  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await axiosInstance.get(`/customer/${customerId}`);
        const { data } = response;
        setCustomer(data);
        setName(data.name);
        setPhoneNumber(data.number);
        setStatus(data.status);
        setSelectedCategory({ value: data.from, label: data.from });
        // Assuming `from` data is fetched from some API and stored in `data.fromOptions`
        setFrom(data.fromOptions);
      } catch (error) {
        console.error("Error fetching customer:", error);
        toast.error("No user found")
      }
    };
    fetchCustomer();
  }, [customerId]);

  const handleCreateCategory = (newCategory) => {
    const newOption = { value: newCategory, label: newCategory };
    setSelectedCategory(newOption);
  };

  const handleDeleteCustomer = () => {
    const isConfirmed = window.confirm('Are you sure you want to delete this customer?');
    
    if (isConfirmed) {
      // Proceed with the delete operation
      // Make the API request to delete the data
      axiosInstance
        .delete(`/customer/${customerId}`)
        .then((response) => {
          toast.success("Customer deleted successfully");
        })
        .catch((error) => {
          toast.error("Error deleting customer");
        });
    } else {
      // Close the confirmation modal
      setShowConfirmationModal(false);
    }
  };
  


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: state.isFocused ? '1px solid #93C5FD' : '1px solid #D1D5DB',
      borderRadius: '0.375rem',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #93C5FD',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9CA3AF',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',  // Set the text color to white
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',  // Set the selected option text color to white
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#6B7280' : 'transparent',
      color: state.isFocused ? '#F3F4F6' : '#4B5563',
    }),
  };

  const handleUpdateCustomer = () => {
    setLoading(true);
    const updatedData = {
      name,
      phoneNumber,
      status,
      from: selectedCategory.value,
    };

    axiosInstance
      .put(`/customer/updatecustomer/${customerId}`, updatedData)
      .then((response) => {
        toast.success("Customer updated successfully");
      })
      .catch((error) => {
        toast.error("Error updating customer");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="relative w-full p-10 flex flex-col gap-4 bg-slate-900 pb-20 h-full font-rubik min-h-screen z-10">
      <div
        aria-hidden="true"
        className="fixed  w-16 h-16 rounded-full bg-gradient-to-b from-blue-200 to-purple-200 blur-3xl mx-auto scale-y-150  top-60 right-0 -z-0"
      ></div>

      <div
        aria-hidden="true"
        className="fixed  w-24 h-16 rounded-full bg-gradient-to-b from-blue-500 to-purple-600 blur-3xl mx-auto scale-y-150  bottom-60 left-0 -z-0"
      ></div>
      <div>
        <h1 className="text-4xl lg:text-6xl font-rubik mb-7 font-bold text-gray-300 ">
          Update Customer
        </h1>
      </div>

      <div className="relative group rounded-lg w-full bg-opacity-20 flex flex-col gap-6">
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Customer Name</h2>
          <input
            name=""
            id=""
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Enter Name"
          />
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Phone number</h2>
          <input
            name=""
            id=""
            type="number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Enter Phone number"
          />
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Reference</h2>
          <CreatableSelect
            options={from}
            isClearable
            styles={customStyles}
            onChange={(selectedOption) => setSelectedCategory(selectedOption)}
            onCreateOption={handleCreateCategory}
            placeholder="Select or type a Reference"
            isSearchable
            value={selectedCategory}
          />
        </div>


        <div>
          {/* Allow users to input a custom category */}

        </div>
        {/* <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Status</h2>
          <select
            name=""
            id=""
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Select Credit type"
          >
            <option className="bg-neutral-700 w-1/2 text-sm">Select Type</option>
            <option className="bg-neutral-700">On Hold</option>
            <option className="bg-neutral-700">On Progress</option>
            <option className="bg-neutral-700">Cancelled</option>
            <option className="bg-neutral-700">Completed</option>
            <option className="bg-neutral-700">Smm</option>
          </select>
        </div>       */}
        <div>
          <h2 className="text-lg font-semibold text-gray-400 mb-2">Status</h2>
          <select
            name=""
            id=""
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="appearance-none hover:placeholder-shown:bg-slate-800 relative text-slate-100 bg-transparent ring-0 outline-none border border-neutral-500 text-neutral-900 placeholder-blue-200  placeholder-opacity-25 text-sm font-bold rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5"
            placeholder="Select Status type"
          >
            <option className="bg-neutral-700 w-1/2 text-sm">Select Status</option>
            <option className="bg-neutral-700">On Hold</option>
            <option className="bg-neutral-700">On Progress</option>
            <option className="bg-neutral-700">Cancelled</option>
            <option className="bg-neutral-700">Completed</option>
          </select>
        </div>


        <button
          onClick={handleUpdateCustomer}
          disabled={loading}
          className={`flex gap-3 cursor-pointer text-white font-semibold bg-gradient-to-r from-purple-600 to-blue-900 px-7 py-3 rounded-lg border border-gray-600 hover:scale-105 duration-200 hover:text-gray-500 hover:border-gray-800 hover:from-slate-800 hover:to-gray-900 justify-center items-center bg-opacity-10 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {loading ? "Updating..." : "Update"}
        </button>
        <button onClick={handleDeleteCustomer} disabled={loading}
          className={`flex gap-3 cursor-pointer text-white font-semibold bg-gradient-to-r from-red-600 to-red-900 px-7 py-3 rounded-lg border border-gray-600 hover:scale-105 duration-200 hover:text-gray-500 hover:border-gray-800 hover:from-slate-800 hover:to-gray-900 justify-center items-center bg-opacity-10 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {loading ? "Deleting..." : "Delete Customer"}
        </button>


      </div>
    </div>
  );
}

export default UpdateCustomer;
